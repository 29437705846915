<template>
  <!-- 区块信息 -->
  <div class="detail-info"
       v-if="blockData !== []">
    <div v-for="(item, index) in blockData"
         :key="index">
      <div class="header">
        <el-row type="flex"
                justify="space-between">
          <div>区块信息</div>
          <div class="header-btn div-row">
            <!-- <el-button type="text"
                       @click="handleLastBlock(item.height - 1)">上个区块</el-button>
            <el-button type="text"
                       @click="handleNextBlock(item.height + 1)">下个区块</el-button> -->
          </div>
        </el-row>
      </div>
      <div class="content">
        <!-- <div class="data-list nothing"
           v-if="!blockData || blockData === null">
        <img src="../img/nothing.png"
             alt="">
        <span class="nothing-text">暂无数据</span>
      </div> -->
        <el-row>
          <el-col class="icon-div"
                  :xs="4"
                  :sm="4"
                  :md="4"
                  :lg="5"
                  :xl="6">
            <i class="el-icon-d-arrow-left icon-btn"
               @click="handleLastBlock(item.shardName, item.height - 1)"></i>
          </el-col>
          <el-col class="details-div div-column"
                  :xs="16"
                  :sm="16"
                  :md="16"
                  :lg="14"
                  :xl="12">
              <div class="div-row details-list">
                <div class="label-title">分片名</div>
                <span class="details-content">{{item.shardName}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">共识算法</div>
                <div class="details-content"
                     v-if="item.promiseCode===101">PoL</div>
                <div class="details-content"
                     v-else-if="item.promiseCode===201">PoS</div>
                <div class="details-content"
                     v-else-if="item.promiseCode===301">Paxos</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">区块高度</div>
                <span class="details-content">{{toThousands(item.height)}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">区块创建者</div>
                <popover :width="500"
                         :shard="item.shardName"
                         :value="item.coinBase"
                         :divStyle="[{'width':420+'px'},{'color':'#3f536e'}]"
                         :num="45"></popover>
                <i class="el-icon-document-copy"
                   @click="copy($event, item.coinBase)"></i>
              </div>
              <div class="div-row details-list">
                <div class="label-title">父区块哈希</div>
                <popover :width="500"
                         :shard="item.shardName"
                         :value="item.parentHash"
                         :divStyle="[{'width':420+'px'},{'color':'#3f536e'}]"
                         :num="45"></popover>
                <i class="el-icon-document-copy"
                   @click="copy($event, item.parentHash)"></i>
              </div>
              <div class="div-row details-list">
                <div class="label-title">出块时间</div>
                <span class="details-content">{{tf(item.timestamp, 'YYYY-MM-DD HH:mm:ss')}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">目标出块间隔/(秒)</div>
                <span class="details-content">{{item.timeline/1000}}</span>
              </div>

              <div class="div-row details-list">
                <div class="label-title">哈希算法</div>
                <div class="details-content"
                     v-if="item.hashAlgorithm===201">sha3</div>
                <div class="details-content"
                     v-else-if="item.hashAlgorithm===202">sm3</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">区块哈希</div>
                <popover :width="500"
                         :shard="item.shardName"
                         :value="item.hash"
                         :divStyle="[{'width':420+'px'},{'color':'#3f536e'}]"
                         :num="45"></popover>
                <i class="el-icon-document-copy"
                   @click="copy($event, item.hash)"></i>
              </div>
              <div class="div-row details-list">
                <div class="label-title">签名算法</div>
                <div class="details-content"
                     v-if="item.signAlgorithm===101">rsa</div>
                <div class="details-content"
                     v-else-if="item.signAlgorithm===102">sm2</div>
              </div>
              <div class="div-row details-list">
                <div class="label-title">签名</div>
                <popover :showDict="false"
                         :width="500"
                         :shard="item.shardName"
                         :value="item.sign"
                         :divStyle="[{'width':420+'px'},{'color':'#3f536e'}]"
                         :num="45"></popover>
                <i class="el-icon-document-copy"
                   @click="copy($event, item.sign)"></i>
              </div>
              <div class="div-row details-list">
                <div class="label-title">打包目标数</div>
                <span class="details-content">{{item.difficulty}}</span>
              </div>
              <div class="div-row details-list">
                <div class="label-title">打包账本数</div>
                <span style="color: #3f536e;margin-right:20px;">{{item.size}}</span>
                <el-button v-if="item.size !== 0"
                           type="text"
                           icon="ym-icon-liulan"
                           @click="handleOpen(item.height, item.shardName)"> 查看</el-button>
              </div>
              <div class="div-row details-list">
                <div class="label-title">版本号</div>
                <span class="details-content">{{item.version}}</span>
              </div>
          </el-col>
          <el-col v-if="!item.nextBlock"
                  class="icon-div"
                  :xs="4"
                  :sm="4"
                  :md="4"
                  :lg="5"
                  :xl="6">
            <i class="el-icon-d-arrow-right icon-btn"
               @click="handleNextBlock(item.shardName, item.height + 1)"></i>
            <!-- <span>{{item.nextBlock}}</span> -->
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 循环体 -->
    <!-- Dialog -->
    <el-dialog title="区块打包的账本列表"
               width="78%"
               :visible.sync="dialogVisible">

      <table-list :columns="columns"
                  :data="dealsList"
                  fit
                  stripe
                  :tableOption="{size: 'mini'}"
                  :pageData="pageData"
                  @handlePageChange="handlePageChange"
                  @handleSizeChange="handleSizeChange">

        <!-- 账本哈希 -->
        <template #hash="{row}">

          <div class="div-row"
               v-if="row.hash">
            <popover :width="500"
                     :shard="row.shardName"
                     :value="row.hash"
                     :divStyle="[{'margin-right': 10+'px'}]" :isSubStr="false" :divClass="'shenglue-1'"
                     ></popover>
            <i class="el-icon-document-copy"
               @click="copy($event, row.hash)"></i>
          </div>
          <span v-else-if="!row.hash">无</span>
        </template>

        <!-- 创建地址 -->
        <template #sendAddress="{row}">
          <div class="div-row"
               v-if="row.sendAddress">
            <popover :width="500"
                     :shard="row.shardName"
                     :value="row.sendAddress"
                     :divStyle="[{'margin-right': 10+'px'}]" :isSubStr="false" :divClass="'shenglue-1'"
                     ></popover>
            <i class="el-icon-document-copy"
               @click="copy($event, row.sendAddress)"></i>
          </div>
          <span v-else-if="!row.sendAddress">无</span>
        </template>

        <!-- 时间戳 -->
        <template #timestamp="{row}">
          {{ tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss') }}
        </template>

        <!-- 备注 -->
        <template #remark="{row}">
          <popover v-if="row.remark"
                   :width="500"
                   :shard="row.shardName"
                   :value="row.remark"
                   :divClass="'shenglue-1'"
                   :isSubStr="false"></popover>
          <div v-else>无</div>
        </template>
      </table-list>

    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import timeFormat from '@/utils/timeFormat.js'
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import publicFunction from '@/utils/publicFunction.js'
export default {
  name: 'blockDetail',
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      height: 0, // 当前区块高度
      shardName: null, // 当前分片名
      blockData: [],
      dialogVisible: false,
      // dealsData: [], // 账本原数据
      dealsList: [], // 账本列表
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        total: 0, // 数据总量
        pageSizes: [10, 15, 30], // 每页的条数跳转页面
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: [
        {
          label: '序号',
          type: 'index',
          align: 'center',
          show: true,
          width: 80
        },
        {
          label: '分片名',
          prop: 'shardName',
          align: 'center',
          show: true,
          width: 100
        },
        {
          label: '账本类型',
          prop: 'ledgerCodeName',
          align: 'center',
          show: true,
          width: 100
        },
        {
          label: '账本哈希',
          prop: 'hash',
          show: true,
          enableSlot: true
        },
        {
          label: '创建地址',
          prop: 'sendAddress',
          show: true,
          enableSlot: true
        },
        {
          label: '时间戳',
          prop: 'timestamp',
          show: true,
          enableSlot: true,
          width: 200
        },
        {
          label: '备注',
          prop: 'remark',
          show: true,
          enableSlot: true
        },
        {
          prop: 'operators',
          label: '操作',
          fixed: 'right',
          align: 'right',
          width: 100,
          children: [
            {
              icon: 'el-icon-view',
              label: '查看',
              clickEvent: (thisVue, row) => {
                this.handleDetail(row.hash)
              },
              showFilter: () => {
                return true
              }
            }
          ]
        }
      ]
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听区块:', this.datas)
        // 清空数据
        this.blockData = []
        // 查询区块信息
        this.getData()
      },
      deep: false,
      immediate: false // 第一次不会立刻监听handler事件
    }
  },
  created() {
    // console.log(window.innerHeight - 214)
  },
  mounted() {
    // console.log('触发区块组件')
    // 查询区块信息
    setTimeout((res) => {
      this.getData()
    }, 1000)
  },
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getLedger(1, this.height, this.shardName)
      // this.getList(this.dealsData, 1, number, this.dealsData.length)
    },
    // 跳转分页
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getLedger(goPage, this.height, this.shardName)
      // this.getList(
      //   this.dealsData,
      //   goPage,
      //   this.pageData.size,
      //   this.dealsData.length
      // )
    },
    // 跳转详情页
    handleDetail(hash) {
      // console.log('hash:', hash)
      this.$router.push({
        name: 'ledgerDetails',
        query: {
          searchKey: hash
        }
      })
    },
    // 打开弹窗
    handleOpen(height, shardName) {
      this.dialogVisible = true
      // 设置区块高度
      this.height = height
      this.shardName = shardName
      this.dealsList = []
      // 获取账本列表
      this.getLedger(1, height, shardName)
      this.pageData.currentPage = 1
      // 分页取数据每次（分页设置：10/20/30）
      // this.getList(deals, 1, 10, deals.length)
      // 存放当前账本信息
      // this.dealsData = deals
    },
    // 获取分页后列表数据
    // getList(list, page, pageSize, total) {
    //   const tableData = this.getTableData(list, page, pageSize, total)
    //   this.dealsList = tableData.data
    //   this.pageData.total = total
    //   this.pageData.currentPage = page
    // },
    // // 分页获取数据
    // getTableData(list, page, pageSize, total) {
    //   const tableData = {}
    //   if (pageSize >= total) {
    //     // pageSize大于等于总数据长度，说明只有1页数据或没有数据
    //     tableData.data = list
    //     tableData.page = 1 // 直接取第一页
    //   } else {
    //     // pageSize小于总数据长度，数据多于1页
    //     const num = pageSize * (page - 1) // 计算当前页（不含）之前的所有数据总条数
    //     if (num < total) {
    //       // 如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
    //       const startIndex = num // 当前页第一条数据在总数据集中的索引
    //       const endIndex = num + pageSize - 1 // 当前页最后一条数据索引
    //       tableData.data = list.filter(
    //         (_, index) => index >= startIndex && index <= endIndex
    //       ) // 当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
    //     } else {
    //       // 当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
    //       const size = parseInt(length / pageSize) // 取商
    //       const rest = length % pageSize // 取余数
    //       if (rest > 0) {
    //         // 余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
    //         tableData.page = size + 1 // 当前页码重置，取size+1
    //         tableData.data = list.filter(
    //           (_, index) => index >= pageSize * size && index <= length
    //         )
    //       } else if (rest === 0) {
    //         // 余数等于0，最后一页数据条数正好是pageSize
    //         tableData.page = size // 当前页码重置，取size
    //         tableData.data = list.filter(
    //           (_, index) => index >= pageSize * (size - 1) && index <= length
    //         )
    //       } // 注：余数不可能小于0
    //     }
    //   }
    //   return tableData
    // },
    // 判断字符串是否包含字母
    hasLetter(str) {
      for (var i in str) {
        var asc = str.charCodeAt(i)
        // 包含字母
        // eslint-disable-next-line no-mixed-operators
        if ((asc >= 65 && asc <= 90) || (asc >= 97 && asc <= 122)) {
          return true
        }
      }
      return false
    },
    // 判断是否包含中文
    hasChinese(value) {
      if (escape(value).indexOf('%u') < 0) {
        return false // 不包含中文
      } else {
        return true // 包含中文
      }
    },
    // 查询区块信息
    async getData() {
      let options = {
        shard: this.datas.shard,
        hash: this.datas.searchKey,
        height:
          this.hasLetter(this.datas.searchKey) ||
          this.hasChinese(this.datas.searchKey)
            ? null
            : this.datas.searchKey
      }
      let res = await this.$api.chainQL.blockDetail(options)
      // console.log(res)

      if (res.data) {
        if (res.data.listBlock.block.length !== 0) {
          let blockData = res.data.listBlock.block
          // 对数据进行匹配再组装最后进行赋值
          for (let item of blockData) {
            // 获取是否最大高度
            this.getHeightMax(item.shardName, item.height).then((res) => {
              item.nextBlock = res
            })
          }
          setTimeout((res) => {
            this.blockData = blockData
            this.$nextTick((res) => {
              this.$emit('block-say', 3)
              // console.log('数据已经更新')
            })
          }, 200)
        } else {
          // 显示暂无数据
          this.$emit('block-say', 2)
        }
      } else {
        // 显示暂无数据
        this.$emit('block-say', 2)
      }
    },
    // 判断当前区块是否有账本存在
    // async hasLedger(shardName, height) {
    //   let options = {
    //     page: 0,
    //     limit: 10,
    //     shard: shardName,
    //     height: height
    //   }
    //   const { data } = await this.$api.chainQL.blockHeightLedger(options)
    //   if (data.listLedger.total !== 0) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // 根据区块高度查询账本列表
    async getLedger(currentPage, height, shardName) {
      if (currentPage === 1) {
        let options = {
          page: 0,
          limit: this.pageData.size,
          shard: shardName,
          height: height
        }
        const { data } = await this.$api.chainQL.blockHeightLedger(options)
        // console.log(data)
        this.dealsList = data.listLedger.ledger
        this.pageData.total = data.listLedger.total
      } else if (currentPage > 1) {
        let options = {
          page: this.pageData.size * (currentPage - 1),
          limit: this.pageData.size,
          shard: shardName,
          height: height
        }
        const { data } = await this.$api.chainQL.blockHeightLedger(options)
        // console.log(data)
        this.dealsList = data.listLedger.ledger
        this.pageData.total = data.listLedger.total
      }
    },
    // 查询上一个区块
    async handleLastBlock(shard, height) {
      let options = {
        page: 0,
        limit: this.pageData.size,
        shard: shard,
        height: height
      }
      // 初始化数据
      this.blockData = []
      // 设置为loading
      this.$emit('block-say', 1)
      let { data } = await this.$api.chainQL.blockForHeight(options)

      if (data.listBlock.block.length !== 0) {
        let blockData = data.listBlock.block
        // 对数据进行匹配再组装最后进行赋值
        for (let item of blockData) {
          this.getHeightMax(item.shardName, item.height).then((res) => {
            item.nextBlock = res
          })
        }
        setTimeout((res) => {
          this.$emit('block-say', 3)
          this.blockData = blockData
        }, 200)
      } else {
        // 显示空数据图
        this.$emit('block-say', 2)
      }
    },
    // 查询下一个区块
    async handleNextBlock(shard, height) {
      let options = {
        page: 0,
        limit: this.pageData.size,
        shard: shard,
        height: height
      }
      // 初始化数据
      this.blockData = []
      // 设置为loading
      this.$emit('block-say', 1)
      let { data } = await this.$api.chainQL.blockForHeight(options)

      if (data.listBlock.block.length !== 0) {
        let blockData = data.listBlock.block
        // 对数据进行匹配再组装最后进行赋值
        for (let item of blockData) {
          this.getHeightMax(item.shardName, item.height).then((res) => {
            item.nextBlock = res
          })
        }
        setTimeout((res) => {
          this.$emit('block-say', 3)
          this.blockData = blockData
        }, 200)
      } else {
        // 显示空数据图
        this.$emit('block-say', 2)
      }
    },
    // 获取最大区块高度比较
    async getHeightMax(shard, height) {
      let { data } = await this.$api.chainQL.heightMax(shard)
      // console.log(data.listBlock.heightMax)
      if (height === data.listBlock.heightMax) {
        return true
      } else {
        return false
      }
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>

<style lang="less" scoped>
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.detail-info {
  min-width: 1100px;
  margin-bottom: 16px;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 16px;
    border-bottom: 1px solid #cecece;
    background: #fff;
    width: 100%;
    .header-btn {
      height: 20px;
      margin-top: -10px;
    }
  }
  .content {
    background: #fff;
    // 图标
    .icon-div {
      margin-top: 260px;
      text-align: center;
    }
    .icon-btn {
      font-size: 64px;
    }
    .div-column {
      display: flex;
      flex-direction: column;
    }
    .div-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .details-div {
      margin: 12px auto;
    }
    .details-list {
      line-height: 50px;
      color: #757575;
      // padding-left: 32px;
      .label-title {
        padding-right: 60px;
        width: 220px;
        text-align: right;
        font-weight: bold;
      }
      .details-content {
        // font-weight: bold;
        color: #3f536e;
        width: 420px;
      }
    }
    .data-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.nothing {
        padding-bottom: 20px;
        margin: 20px auto;
        font-size: 14px;
        color: #222b45;
        img {
          width: 104px;
          height: 105px;
        }
        .nothing-text {
          margin-left: -11px;
        }
      }
    }
  }
}
</style>
