<template>
  <div class="details-page ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @search="search"
                 @select="select"></search-form>
    <!-- 显示nothing -->
    <div class="data-list nothing"
         v-if="block === 2 && ledger === 2 && account === 2">
      <img src="./img/nothing.png"
           alt="">
      <span class="nothing-text"
            style="margin-left: -18px;">暂无数据</span>
    </div>
    <!-- 显示loading -->
    <div class="data-list nothing"
         v-if="block === 1 && ledger === 1 && account === 1">
      <i class="el-icon-loading"
         style="font-size:50px"></i>
      <span class="nothing-text">加载中</span>
    </div>
    <!-- 当区块信息正在渲染时还是显示loading -->
    <div class="data-list nothing"
         v-if="block === 1 && ledger === 2 && account === 2">
      <i class="el-icon-loading"
         style="font-size:50px"></i>
      <span class="nothing-text">加载中</span>
    </div>
    <!-- 区块信息 -->
    <block-detail :datas="searchValue"
                  v-on:block-say="getFromBlock"></block-detail>
    <!-- 账本信息 -->
    <ledger-detail :datas="searchValue"
                   v-on:ledger-say="getFromLedger"></ledger-detail>
    <!-- 账户信息 -->
    <account-detail :datas="searchValue"
                    v-on:account-say="getFromAccount"></account-detail>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import BlockDetail from './component/blockDetail.vue'
import LedgerDetail from './component/ledgerDetail.vue'
import AccountDetail from './component/accountDetail.vue'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    BlockDetail,
    LedgerDetail,
    AccountDetail
  },
  name: 'chainDataDetails',
  data() {
    return {
      // hasInfo: 1, // 默认1:显示loading/2:显示暂无数据/3:显示数据
      block: 1,
      ledger: 1,
      account: 1,
      // 表单
      form: {
        shard: null, // 分片名（默认全部）
        searchKey: '' // 搜索条件
      },
      searchValue: {
        shard: null, // 分片名（默认全部）
        searchKey: '' // 搜索条件
      }
    }
  },
  created() {},
  mounted() {
    if (getShardName()) {
      this.form.shard = getShardName()
      this.searchValue.shard = getShardName()
    }
    if (this.$route.query.searchKey) {
      this.form.searchKey = this.$route.query.searchKey
      this.searchValue.searchKey = this.$route.query.searchKey
    }
    // console.log('触发器')
  },
  methods: {
    // 存储分片名
    select(shard) {
      setShardName(shard)
    },
    // 点击查询
    search(_form) {
      // 重置查询数据
      this.form = _form
      // 监听查询数据
      this.searchValue = _form
      // 搜索前恢复显示loading
      // this.hasInfo = 1
      this.block = 1
      this.ledger = 1
      this.account = 1
      // console.log('点击查询', _form)
    },
    getFromBlock(value) {
      this.block = value
      if (value === 1) {
        this.ledger = 1
        this.account = 1
      }
    },
    getFromLedger(value) {
      this.ledger = value
    },
    getFromAccount(value) {
      if (value.Tokens === 3 || value.Deposit === 3 || value.nonToken === 3) {
        this.account = 3
      } else {
        this.account = 2
      }
    }
  }
}
</script>

<style lang="less" scoped>
.details-page {
  .data-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.nothing {
      padding-bottom: 20px;
      margin: 200px auto;
      font-size: 14px;
      img {
        width: 180px;
        height: 181px;
      }
      .nothing-text {
        margin-top: 10px;
        color: #888888;
      }
    }
  }
}
</style>
